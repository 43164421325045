import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';

import { AppListData, AppPortalData } from '@yuno/api/interface';

import { AppsService } from '../services';

type AppListState = {
	data: AppListData[];
	selectedData: AppPortalData | null;
	isLoading: boolean;
	filter: { query: string };
};

const initialState: AppListState = {
	data: [],
	selectedData: null,
	isLoading: false,
	filter: { query: '' }
};

export const AppListStore = signalStore(
	{ providedIn: 'root', protectedState: false },
	withState(initialState),
	withComputed(({ data, filter }) => ({
		filtered: computed(() => {
			const query = filter().query.toLowerCase();
			return data().filter(f => {
				const oid = f._id?.toLowerCase().includes(query);
				const id = f.id.toLowerCase().includes(query);
				const title = f.config?.title?.toLowerCase().includes(query);

				return oid || id || title;
			});
		})
	})),
	withMethods((state, appsService = inject(AppsService)) => ({
		reset: () => patchState(state, initialState),
		setQuery(query: string) {
			patchState(state, { filter: { query } });
		},
		getAppList: rxMethod<void>(
			pipe(
				tap(() => patchState(state, { isLoading: true })),
				switchMap(() =>
					appsService.getAppList().pipe(
						tapResponse({
							next: data => {
								patchState(state, { data, isLoading: false });
							},
							error: () => {
								patchState(state, { data: initialState.data, isLoading: false });
							}
						})
					)
				)
			)
		)
	}))
);
